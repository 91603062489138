<template>
    <vs-popup class="machineAddClose_popup" title="Ajouter Fermeture" :active.sync="popupActive">
        <template v-if="loading">
            <h3>Chargement ...</h3>
        </template>
        <template v-else>
            <div class="vx-col w-full">
                <vs-input class="w-full" label="Date de début" v-model="debut" type="date"/>
            </div>
            <br/>
            <div class="vx-col w-full">
                <vs-input class="w-full" label="Date de fin" v-model="fin" type="date"/>
            </div>

            <br/>
            <div class="vx-col w-full">
                <vs-select v-model="ma" class="w-full select-large"  label="Moment de la journée">
                    <vs-select-item value="0" text="Journée" />
                    <vs-select-item value="-1" text="Matin" />
                    <vs-select-item value="1" text="Après-midi" />
                </vs-select>
            </div>
            <br/>
            <div class="vx-col w-full">
                <vs-input class="w-full" label="Heure de basculement matin/Après-midi" v-model="hour" type="time"/>
            </div>
            <br/>

            <vs-row>
                <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                    <div></div>
                    <vs-button color="success" type="filled" v-on:click="add()" icon-pack="feather" icon="icon-plus">Ajouter</vs-button>
                </vs-col>
            </vs-row>
        </template>
    </vs-popup>
</template>

<style lang="scss">
.machineAddClose_popup
{

}
</style>

<script>

import MachineClose from '@/database/models/booking_close';
import Machine  from '@/database/models/machine';
import Fdate from '@/assets/utils/fdate';

export default {
    components:{
	},
    props: {
    },
    data(){
        return{
            popupActive:false,
            callback:null,

            debut: null,
            fin  : null,
            ma   : 0,
            hour : "12:00",

            loading : false,
        }
    },
    watch:{
        $route (){
        },
        debut(){
            this.fin = this.debut;
        }
    },
    beforeMount:function(){
        
    },
    methods:{
        openPopup( callback )
        {
            this.callback    = null
            this.popupActive = true

            if(callback)
                this.callback = callback

            this.debut = null;
            this.fin   = null;
            this.ma    = 0;
            this.hour  = "12:00";
            this.loading = false;
        },
        async add()
        {
            //Verifier que la date de dévbut et plus petite que la date de fin
            if( (new Date(this.debut)).getTime() > (new Date(this.fin)).getTime() )
            {
                alert("Date de fin plus petite que date de début !");
                return;
            }


            //Début création
            this.loading = true;
            let date_fin = new Date( this.fin );
            Machine.getTabAllMemory(async(list_machine)=>
            {
                //Lister hour
                for (var day = new Date(this.debut);   day<=date_fin;   day.setDate(day.getDate()+1) )
                {
                    //Lister machine
                    for( var i=0; i< list_machine.length; i++)
                    {
                        //console.log("Add: "+Fdate.getISO_8601(day)+" Mach :"+list_machine[i].uid );
                        await MachineClose.add({
                            machine : list_machine[i].uid,
                            date    : Fdate.getISO_8601(day),
                            ma      : this.ma,
                            hour    : this.hour,
                        });
                    }
                }

                //end
                this.popupActive = false
                if(this.callback)
                    this.callback();
            });
        }
    }
}

</script>