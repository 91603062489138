<template>
        <div class="option_planning">
        <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <h3>Fermeture</h3>
                <vs-button color="success" type="filled" v-on:click="openAddMachine" icon-pack="feather" icon="icon-plus" >Ajouter</vs-button>
            </vs-col>
        </vs-row>

        <vs-divider/>
        <div>
            <vs-table v-model="selected" pagination max-items="20" search :data="list_close">

                <!-- header table -->
                <template slot="thead">
                    <vs-th sort-key="print">Date</vs-th>
                    <vs-th sort-key="ma">Journée</vs-th>
                    <vs-th sort-key="">Supprimer</vs-th>
                    <!--TODO GROUPE-->
                </template>

                <!--Body table-->
                <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                        <vs-td :data="data[indextr].print">
                            <span>{{ data[indextr].print }}</span>
                        </vs-td>

                        <vs-td :data="data[indextr].ma">
                            <template v-if="data[indextr].ma == 0">
                                <span>Journée</span>
                            </template>
                            <template v-if="data[indextr].ma == -1">
                                <span>Matin</span>
                            </template>
                            <template v-if="data[indextr].ma == 1">
                                <span>Après Midi</span>
                            </template>
                        </vs-td> 

                        <vs-td :data="data[indextr].print">
                            <vs-icon icon="close" size="20px" color="red" class="cursor" v-on:click="fdelete(data[indextr].date, data[indextr].ma)"></vs-icon>
                        </vs-td> 

                    </vs-tr>
                </template>
            </vs-table>
        </div>
        <AddMachineClose ref="addMachineClose" />
    </div>
</template>



<style lang="scss">
</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'

import MachineClose from '@/database/models/booking_close'

import AddMachineClose from '@/components/machine/addClose.vue'

import Fdate from '@/assets/utils/fdate'

export default {
    components:{
        AddMachineClose,
	},
    data(){
        return{
            list_close:[],
            selected:[],
        }
	},
	mounted:function()
	{
        //autoRefresh
		autoRefresh.SetCallback(()=>{
            //refresh
            MachineClose.getTabAllMemory((list)=>
            {
                //triage par jour
                list.sort(function(a, b)
                {
                    let time_a = (new Date(a.date)).getTime();
                    let time_b = (new Date(b.date)).getTime();
                    return time_a - time_b;
                });

                let tab = {};
                //fusion machine
                for( var i=0; i<list.length; i++)
                {
                    let buffer = list[i];
                    if( tab[ buffer.date+buffer.ma ] == undefined )
                    {
                        buffer.print =Fdate.getDate(buffer.date);
                        tab[ buffer.date+buffer.ma ] = buffer;
                    }
                }

                //Remise format tableau
                let tab2 = [];
                for( var elem in tab )
                    tab2.push( tab[elem] );

                this.list_close = tab2;
            })
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            autoRefresh.refresh()
        }
    },
    methods:{
		openAddMachine(){
            this.$refs.addMachineClose.openPopup(()=>{
                autoRefresh.refresh();
            })
        },

        async fdelete( _date, ma )
        {
            if(!confirm("Confirmez-vous la suppression ?"))
                return;

            let date_del = Fdate.getISO_8601(_date);

            //Supression
            MachineClose.getTabAllMemory(async (list)=>
            {
                for( var i=0; i<list.length; i++)
                if( Fdate.getISO_8601(list[i].date) == date_del)
                if( list[i].ma == ma )
                    await MachineClose.remove( list[i].uid );

                //fin
                alert("Suppression terminée");
                autoRefresh.refresh();
            });
        },
    }
};

</script>